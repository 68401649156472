import * as CookieConsent from "vanilla-cookieconsent";

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "bar",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
    },
    analytics: {},
  },
  language: {
    default: "pt-BR",
    translations: {
      "pt-BR": {
        consentModal: {
          title: "Nossos usos de cookies!",
          description:
            "Este site utiliza cookies que nos ajudam a aprimorar a plataforma conforme seu uso. Esses cookies serão armazenados apenas se você aceitar.",
          acceptAllBtn: "Aceitar",
          acceptNecessaryBtn: "Rejeitar",
          showPreferencesBtn: "Preferências",
        },
        preferencesModal: {
          title: "Preferências de cookies",
          acceptAllBtn: "Aceitar",
          acceptNecessaryBtn: "Rejeitar",
          savePreferencesBtn: "Salvar preferências",
          closeIconLabel: "Fechar",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title:
                'Cookies necessários <span class="pm__badge">Sempre ativado</span>',
              description:
                "Esses cookies são necessários para o funcionamento do site.",
              linkedCategory: "necessary",
            },
            {
              title: "Cookies de analytics",
              description:
                "Esses cookies são utilizados para entender o comportamento do usuário no site.",
              linkedCategory: "analytics",
            },
            {
              title: "Mais informações",
              description:
                'Qualquer dúvida em relação a utilização de cookies por esse site, por favor <a class="cc__link" href="mailto:suporte@rybo.com.br">entre em contato</a>.',
            },
          ],
        },
      },
    },
  },
});
