import * as Sentry from "@sentry/browser";
import { Tokens } from ".";

Sentry.init({
  dsn: Tokens.sentry,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
